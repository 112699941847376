import sanityClient from '@sanity/client'
import sanityImage from '@sanity/image-url'

export const client = sanityClient({
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    dataset: 'production',
    useCdn: true,
    apiVersion: '2021-08-31',
    token: process.env.REACT_APP_SANITY_TOKEN,
})

const builder = sanityImage(client);

export const urlFor = source => builder.image(source);
