import { NavigationDots, SocialIcons } from "../components";

const AppWrap = (Component, idName, classNames) =>
  function HOC() {
    return (
      <div id={idName} className={`app__container ${classNames}`}>
        <SocialIcons />
        <div className="app__wrapper app__flex">
            <Component />

            <div className="copyright">
                <p className="p-text">© 2022 PRATYASHA</p>
                <p className="p-text">ALL RIGHTS RESERVED</p>
            </div>
        </div>
        <NavigationDots active={idName} />
      </div>
    );
  };

export default AppWrap;
