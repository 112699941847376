import { BsGithub, BsInstagram, BsLinkedin } from "react-icons/bs";
import "./SocialIcons.scss";
import { useEffect } from "react";

const SocialIcons = () => {
  const redirect = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="app__social">
      <div onClick={() => redirect("https://github.com/pratyashha")}>
        <BsGithub />
      </div>
      <div onClick={() => redirect("https://instagram.com/venom_rose___")}>
        <BsInstagram />
      </div>
      <div onClick={() => redirect("https://www.linkedin.com/in/pratyasha-tripathy-596a141ab/")}>
        <BsLinkedin />
      </div>
    </div>
  );
};

export default SocialIcons;
